import './App.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useState } from 'react';
import { useTopDataStore } from './data/TopDataStoreProvider';
import PasswordModal from './components/PasswordModal';

export default function Profile() {
    const { topData } = useTopDataStore();
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <Container>
            <Row>
                <Col xs="12" lg="7">
                    <h2 className="pageTitle mt-4">
                        Profile
                    </h2>
                    <p className="welcomeText">
                        To update your profile information, contact the admin for your workspace.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs="12" lg="6">
                    <Form className="d-flex flex-column gap-3">
                        <Form.Group 
                            controlId="formUserName" 
                            className="d-flex flex-column gap-0 align-items-start"
                        >
                            <Form.Label 
                                className="label-style-light"
                            >
                                Name
                            </Form.Label>
                            <Form.Control 
                                type="text" 
                                value={topData.userName ?? ""} 
                                className="form-control-light"
                                disabled />
                        </Form.Group>
                        <Form.Group 
                            controlId="formCompany" 
                            className="d-flex flex-column gap-0 align-items-start"
                        >
                            <Form.Label 
                                className="label-style-light"
                            >
                                Company
                            </Form.Label>
                            <Form.Control 
                                type="text" 
                                value={topData.company ?? "Asclepia"} 
                                className="form-control-light"
                                disabled />
                        </Form.Group>
                        <Form.Group controlId="formRole" 
                            className="d-flex flex-column gap-0 align-items-start"
                        >
                            <Form.Label 
                                className="label-style-light"
                            >
                                Role
                            </Form.Label>
                            <Form.Control 
                                type="text" 
                                value={topData.role ?? ""} 
                                className="form-control-light"
                                disabled 
                            />
                        </Form.Group>
                    </Form>
                        <Button 
                            variant="primary" 
                            onClick={handleShow}
                            className="button-save-light mt-5"
                            style={{ width: 'auto' }}
                        >
                            Reset Password
                        </Button>
                </Col>
            </Row>
            <PasswordModal show={showModal} handleClose={handleClose} />
        </Container>
    )
}