import './App.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useTopDataStore } from './data/TopDataStoreProvider';
import { useEffect } from 'react';


// Main Dashboard component
export default function Dashboard() {
  const {topData} = useTopDataStore();
  console.log(topData);

  return (
    <Container>
      <Row>
        <Col xs="6">
          <p className="welcomeText">
            Welcome to Kinetika by Asclepia! This dashboard will get you oriented to your document, 
            show team notifications, and assist with project management 
            by providing the team a quick view of the phase of document 
            creation.
          </p>
        </Col>
      </Row>
    </Container>
  );
}