import { useEffect, useRef } from "react";
import TopDataStoreProvider, { useTopDataStore } from "./data/TopDataStoreProvider";
import Login from "./login";
import Confirm from "./login.confirm";
import Wrapper from "./asc";
import Offline from "./offline";
import WorkspaceDataStoreProvider from "./data/WorkspaceDataStoreProvider";
import VariableDataStoreProvider from "./data/VariableDataStoreProvider";
import TemplateDataStoreProvider from "./data/TemplateDataStoreProvider";
import DocumentDataStoreProvider from "./data/DocumentDataStoreProvider";
import StudyDataStoreProvider from "./data/StudyDataStoreProvider";
import Cookies from 'js-cookie';
import LoadDataStoreProvider from "./data/LoadDataStoreProvider";

function Main() {
  const { topData, setTopData } = useTopDataStore();
  const initialized = useRef(false);
  
  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });   
    if (params.code) {
      const [userId, invitee, ts, document, company, permission, groupName, hashed, password] = atob(params.code).split('|');
      setTopData({ 
        ...topData, 
        page : "newuser",
        inviteInfo : {
          'userId' : userId,
          'invitee' : invitee,
          'ts' : ts,
          'document' : document,
          'hashed' : hashed,
          'companyId' : company,
          'permission' : permission,
          'groupName' : groupName,
          'groupPassword' : password,
        },
        inputEmailClass : '',
        userEmailClass : '',
        userNameClass : '',
        userPasswordClass : '',
        confirmPasswordClass : '',
      }).then(updatedData => {
      }).then(updatedData => {
      });
    } 
  }, []);
  
  useEffect(() => {
    if(!topData.Authorization)
    {
      if(Cookies.get('user'))
      {
        const user = JSON.parse(Cookies.get('user'));

        setTopData({
          ...topData,
          Authorization : user.Authorization,
          token : user.Authorization, // TO DO: Clean up token vs. Authorization
          AccessKeyId : user.AccessKeyId, 
          SecretAccessKey : user.SecretAccessKey, 
          nonce : user.nonce, 
          permissions : user.permissions, 
          userName : user.userName,
          userEmail : user.userEmail,
          userId : user.userId
        });
      }
    }
  }, [topData.page]);

  switch (topData.page) {
    case "edit":
    case "existing":
    case "welcome":
    case "manageDocuments":
    case "manageSpecificDocument":
    case "upload":
    case "templates":
    case "templateSetup":
    case "templateText":
    case "documentPageDisambiguate":
    case "templateSaveChoices":
    case "variables":
    case "createDocOutlet":
    case "studyInformation":
    case "objectives":
    case 'studyDesign':
    case 'patientPopulation':
    case '_variables':
    case 'projectManagement':
    case 'references':
    case 'documentList':
    case 'profile':
      return <Wrapper />;    
    case "confirm":
      return (topData.Authorization) ? <Wrapper /> : <Confirm />;
    case "offline":
      return <Offline />;
    default:
      return (topData.Authorization) ? <Wrapper /> : <Login />;
  }
}

function App() {
  return (
      <WorkspaceDataStoreProvider>
        <LoadDataStoreProvider>
        <TopDataStoreProvider>
          <DocumentDataStoreProvider>
          <StudyDataStoreProvider>
            <TemplateDataStoreProvider>
              <VariableDataStoreProvider>
                <Main />
              </VariableDataStoreProvider>
            </TemplateDataStoreProvider>
            </StudyDataStoreProvider>
          </DocumentDataStoreProvider>
        </TopDataStoreProvider>
        </LoadDataStoreProvider>
      </WorkspaceDataStoreProvider>
  );
}

export default App;
