import '../App.css';
import '../App.css';
import { Navbar, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTopDataStore, initialState } from "../data/TopDataStoreProvider";
import { 
  UserIcon, 
  DocIcon, 
  VarIcon,
  TempIcon,
  Logout,
  Home  } from "./Icons";
import Cookies from 'js-cookie';
import { useWorkspaceDataStore, refreshState } from '../data/WorkspaceDataStoreProvider';


export default function Header(){
  const { topData, setTopData } = useTopDataStore();
  const { setWorkspaceData } = useWorkspaceDataStore();
  
  const logout = (event) => {
    Cookies.remove('user', { path: '/', secure: true, sameSite: 'strict'  });
    Cookies.remove('cc_cookie', { path: '/', sameSite: 'lax' });
    setWorkspaceData(refreshState);
    setTopData(initialState);
    window.location.reload(true);
  }
  
  const profile = (event) => {
    event.preventDefault();
    setTopData({ 
      ...topData, 
      page : 'profile', 
      userPassword : '', 
      newPassword : '', 
      newEmail: topData.userEmail 
    });
  };

  const dashboard = (event) => {
    event.preventDefault();
    setTopData({ 
      ...topData, 
      page: 'welcome', 
    });
  };

  const man_variables = (event) => {
    event.preventDefault();
    setTopData({ 
      ...topData, 
      page: 'variables', 
    });
  };

  const studies = (event) => {
    event.preventDefault();
    setTopData({
      ...topData,
      id : "",
      last_saved : 0,
      page : 'studyList',
    });
  }


  const templates = (event) => {
    event.preventDefault();
    setTopData({
      ...topData,
      id : "", // TO DO: Eliminate this
      last_saved : 0, // TO DO: Eliminate this
      page : 'templates',
    });
  }

  return (
    <Navbar sticky="top" className="p-2">
      <Navbar.Brand href="#home" className="kinetikaOrange">
        kinetika
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <div className="rounded-square compliment m-2 justify-content-center d-inline-flex">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="tooltip-home">dashboard</Tooltip>}
          >
            <Nav.Link onClick={dashboard} disabled={topData.loadingEnvironment}>
              <Home />
            </Nav.Link>
          </OverlayTrigger>
        </div>
        <div className="rounded-square compliment m-2 justify-content-center d-inline-flex">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="tooltip-templates">templates</Tooltip>}
          >
            <Nav.Link onClick={templates} disabled={topData.loadingEnvironment}>
              <TempIcon />
            </Nav.Link>
          </OverlayTrigger>
        </div>
        <div className="rounded-square compliment m-2 justify-content-center d-inline-flex">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="tooltip-studies">studies</Tooltip>}
          >
            <Nav.Link onClick={studies} disabled={topData.loadingEnvironment}>
              <DocIcon />
            </Nav.Link>
          </OverlayTrigger>
        </div>
        <div className="rounded-square compliment m-2 justify-content-center d-inline-flex">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="tooltip-variables">variables</Tooltip>}
          >
            <Nav.Link onClick={man_variables} disabled={topData.loadingEnvironment}>
              <VarIcon />
            </Nav.Link>
          </OverlayTrigger>
        </div>
        <div className="rounded-square compliment m-2 justify-content-center d-inline-flex">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="tooltip-profile">profile</Tooltip>}
          >
            <Nav.Link href="#Profile" onClick={profile}>
              <UserIcon />
            </Nav.Link>
          </OverlayTrigger>
        </div>
        <div className="rounded-square compliment m-2 justify-content-center d-inline-flex">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="tooltip-logout">logout</Tooltip>}
          >
            <Nav.Link onClick={logout}>
              <Logout />
            </Nav.Link>
          </OverlayTrigger>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}