import '../App.css'
export default function LoadingModal() {
    return (
        <div className="modal-overlay">
            <div 
                className="modal-content" 
                style={{ width: '50%' }}
            >
                <h2><span className="kinetikaOrange">kinetika</span> workspace is loading</h2>
                <div className="blinking-dots">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                </div>
            </div>
        </div>
    );
}