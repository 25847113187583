import './styles/login.css';
import { Row, Col, Container, Button, Spinner,
    Form, FloatingLabel, Alert } from 'react-bootstrap';
import { useTopDataStore } from "./data/TopDataStoreProvider";
import { ErrorCircle, LogoIpsum } from "./img/Vectors";
import { callToBackend } from './functions/fetch';
import Cookies from 'js-cookie';


export default function Confirm() {
  
  const { topData, setTopData } = useTopDataStore(); 

  console.log("top data", topData);
  
  // Event listener resends the confirmation code to a user's email
  const resendLink = (event) => {
    event.preventDefault();
    const params = {
      email: topData.userEmail, 
      password: topData.userPassword,
      groupName: topData.groupName
    };

    const url = topData.endpoint + "/login";
    callToBackend(url, params, topData.token, {}, 'POST')
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          setTopData({ 
            ...topData, 
            loginWarning : "A new confirmation code has been sent to your email.",
            confirmCode : "",
            lockLogin : false,
            loading : false,
          });
        })
        .catch((err) => {
          console.log(err);
          alert("Error generating confirmation code.");
        });
      }      
    })
    .catch((err) => {
      console.log(err);
      alert("Error generating confirmation code.");
    });
  }; 
  
  // Action listener validates the user confirmation code
  const sendCode = (event) => {
    event.preventDefault();
    if (!document.getElementById('floatingInput').value.match(/\d\d\d\d\d\d/)) {
      setTopData({
        ...topData,
        loginWarning : "The confirmation code is not the correct format. Please try again or click 'Resend' below.",
        confirmCode : "",
      });
      return false;
    }
  
    setTopData({ ...topData, loading: true });
     // API call to verify the user's credentials with the confirmation code
     const params = {
      email : topData.userEmail, 
      code : topData.confirmCode, 
      password : topData.userPassword,
      groupName : topData.groupName,
    };
    const url = topData.endpoint + "/login";
    callToBackend(url, params, topData.token, {}, 'PUT') 
    .then((res) => {
      res.json().then((data) => {
        if (401 === res.status) {       
          if (data.message) {
            setTopData({
              ...topData,
              loginWarning : data.message,
              confirmCode : "",
              lockLogin : true,
              loading : false,
            });
          }
          else {
            setTopData({
              ...topData,
              loginWarning : "Error validating confirmation code.",
              confirmCode : "",
              loading : false,
              lockLogin : false,
            });
          }            
        }  
        // If confirmation code is validated, fetch the documents for the workspace. 
        else if (200 === res.status) {
          setTopData({
            ...topData,
            AccessKeyId: data.AccessKeyId,
            SecretAccessKey: data.SecretAccessKey,
            nonce: data.nonce,
            permissions: data.permissions,
            userName: data.userName,
            Authorization: data.token,
            token: data.token,
            page: "welcome",
            loading: false,
            isSaving: false,
            loginWarning: "",
          });
          if(topData.cookieConsent){
            const userInfo = {
              Authorization : data.token, 
              token : data.token,
              AccessKeyId : data.AccessKeyId, 
              SecretAccessKey : data.SecretAccessKey, 
              nonce : data.nonce, 
              permissions : data.permissions, 
              userName : data.userName,
              userEmail : topData.userEmail,
              userId : topData.userId,
            };

            var user = JSON.stringify(userInfo);

            Cookies.set('user', user, { 
              expires: 14, 
              secure: true,
              sameSite: 'strict',
              path: '/', 
            });
          }
        }    
      })
    })
    .catch((err) => {
      console.error(err);
      alert("Error validating confirmation code.");
    });

    
  };

  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setTopData({ ...topData, [key]: val });
  };
  
  // Define page layout
  return (
    <Container fluid className="loginCont">
    <div className="screen">
    <Row>
      <Col className="login-container align-middle">
        <Row className="loginBlock">
        <Col className="logoRow align-middle">
          <LogoIpsum />
        </Col>
        <Form onSubmit={sendCode} className="formLogin" id="confirm-screen">
          <>
          {
            (topData.loginWarning === "")  ? "" :
            <Alert 
              className="login-alert" 
              key={'danger'} 
              variant={(topData?.warningType === "status") ? 'primary' : 'danger'}>
              <ErrorCircle/>{topData.loginWarning}
            </Alert>
          }</>
          <h1 className="loginHead">
            Please enter the confirmation code sent to your email
          </h1>
          <Form.Group className="form-floating text-nowrap overflow-x-hidden mb-2">
          <FloatingLabel 
              label="Confirmation code"
            >
              <Form.Control 
                type="text"
                value={topData.confirmCode} 
                className="form-control" 
                name="confirmCode" 
                id="floatingInput" 
                placeholder="******" 
                onChange={formAction} 
                onBlur={formAction} 
                required 
              />
            </FloatingLabel>
          </Form.Group>
          <>
          {
            <Button onClick={sendCode} 
              style={{background: "#FF9B54", borderColor:"#FF9B54"}} 
              size="lg" className="log-in-button mt-3 mb-4" 
              disabled={(topData.lockLogin === true || topData.confirmCode === "") ? true : false}>
              {topData.loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              )}
              {!topData.loading && "SIGN IN"}
            </Button>
          }</>
          <div className="confirm-code mt-5 align-middle confirm-code-shell">
            Didn't receive a confirmation code?
            <Button onClick={resendLink} className="confirm-code conBtn">
              Resend
            </Button>
          </div>
        </Form>
        </Row>
      </Col>
    </Row>
    </div>
  </Container>  
  );
  
}
