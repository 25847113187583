import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { Plus, Minus, Trash } from './Icons';

// Define newVariable globally
const newVariable = {
    variant: 'default',
    example: '',
    placeholder: '',
    select: false,
    options: []
};

const EditVariable = ({ show, handleClose, varset, onSave, isSaving }) => {
    const initialVarsetState = {
        id: "",
        version: "",
        name: '',
        form_section: 'Study Information',
        description: '',
        multiple: false,
        variables: [newVariable]
    };

    const [editedVarset, setEditedVarset] = useState(initialVarsetState);

    useEffect(() => {
        setEditedVarset({
            id: varset?.id || "",
            version: varset?.version || "",
            name: varset?.name || '',
            form_section: varset?.form_section || 'Study Information',
            description: varset?.description || '',
            multiple: varset?.multiple || false,
            variables: varset?.variables || []
        });
    }, [varset]);

    const resetForm = () => {
        setEditedVarset(initialVarsetState);
    };

    if (!varset) {
        return null;
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEditedVarset({
            ...editedVarset,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleVariableChange = (index, field, value) => {
        const updatedVariables = [...editedVarset.variables];
        if (field === 'placeholder') {
            // Strip the # from the start if it exists
            value = value.startsWith('#') ? value.slice(1) : value;
        }
        updatedVariables[index] = {
            ...updatedVariables[index],
            [field]: value,
        };

        if (field === 'select' && value && updatedVariables[index].options.length === 0) {
            updatedVariables[index].options.push('');
        }

        console.log(`handleVariableChange - updatedVariables[${index}]:`, updatedVariables[index]);
        console.log("handleVariableChange - editedVarset:", editedVarset);

        setEditedVarset({
            ...editedVarset,
            variables: updatedVariables,
        });
    };

    const handleSave = () => {
        const labels = editedVarset.variables.map(v => v.variant);
        const placeholders = editedVarset.variables.map(v => v.placeholder);

        // Add # back to placeholders before saving
        const updatedVariables = editedVarset.variables.map(variable => ({
            ...variable,
            placeholder: `#${variable.placeholder}`
        }));

        // Check for duplicate labels
        const hasDuplicateLabels = labels.some((label, index) => labels.indexOf(label) !== index);
        if (hasDuplicateLabels) {
            alert("All variable labels must be unique");
            return;
        }

        // Check for duplicate placeholders
        const hasDuplicatePlaceholders = placeholders.some((placeholder, index) => placeholders.indexOf(placeholder) !== index);
        if (hasDuplicatePlaceholders) {
            alert("All placeholders must be unique");
            return;
        }

        // Check for missing placeholders
        const hasMissingPlaceholders = placeholders.some(placeholder => !placeholder);
        if (hasMissingPlaceholders) {
            alert("All variables must include a placeholder");
            return;
        }

        // Check for placeholders containing spaces
        const hasSpacesInPlaceholders = placeholders.some(placeholder => /\s/.test(placeholder));
        if (hasSpacesInPlaceholders) {
            alert("Placeholder cannot contain spaces");
            return;
        }

        if (editedVarset.name === "Untitled") {
            alert("Provide a Label to save");
            return;
        }
        onSave({ ...editedVarset, variables: updatedVariables });
        resetForm();
        handleClose();
    };

    const handleAddVariable = () => {
        const newVariable = {
            variant: 'default',
            example: '',
            placeholder: '',
            select: false,
            options: []
        };
        setEditedVarset({
            ...editedVarset,
            variables: [...(editedVarset.variables || []), newVariable]
        });
    };

    const handleAddOption = (index) => {
        const updatedVariables = [...editedVarset.variables];
        updatedVariables[index].options.push('');
        setEditedVarset({
            ...editedVarset,
            variables: updatedVariables,
        });
    };

    const handleOptionChange = (varIndex, optIndex, value) => {
        const updatedVariables = [...editedVarset.variables];
        updatedVariables[varIndex].options[optIndex] = value;
        setEditedVarset({
            ...editedVarset,
            variables: updatedVariables,
        });
    };

    const handleRemoveOption = (varIndex, optIndex) => {
        const updatedVariables = [...editedVarset.variables];
        updatedVariables[varIndex].options.splice(optIndex, 1);
        setEditedVarset({
            ...editedVarset,
            variables: updatedVariables,
        });
    };

    const handleRemoveVariable = (index) => {
        const updatedVariables = [...editedVarset.variables];
        updatedVariables.splice(index, 1);
        setEditedVarset({
            ...editedVarset,
            variables: updatedVariables,
        });
    };

    return (
        <Modal 
            show={show} 
            size="lg"
            onHide={handleClose} 
            className="dark-modal"
            backdropClassName="dark-backdrop"
        >
            <Modal.Header 
                closeButton={false}
                style={{ borderBottom: 'none' }}
            >
                <Modal.Title className="modal-form-title">Edit Variable Set</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row gap-3 w-100">
                        <Form.Group 
                            controlId="formVarsetName"
                            className="d-flex flex-column gap-0 align-items-start w-75"
                        >
                            <Form.Label className="label-style-dark">
                                Label
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={editedVarset.name}
                                onChange={handleChange}
                                className="form-control-dark"
                            />
                        </Form.Group>
                        <Form.Group 
                            controlId="formVarsetCategory" 
                            className="d-flex flex-column gap-0 align-items-start"
                        >
                            <Form.Label className="label-style-dark">
                                Category
                            </Form.Label>
                            <Form.Control
                                as="select"
                                name="form_section"
                                value={editedVarset.form_section}
                                onChange={handleChange}
                                className="form-control-dark"
                            >
                                <option value="Study Information">Study Information</option>
                                <option value="Objectives">Objectives</option>
                                <option value="Study Design">Study Design</option>
                                <option value="Patient Population">Patient Population</option>
                                <option value="Variables">Variables</option>
                                <option value="Project Management">Project Management</option>
                                <option value="References">References</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <Form.Group 
                        controlId="formVarsetDescription" 
                        className="d-flex flex-column gap-0 align-items-start"
                    >
                        <Form.Label className="label-style-dark">
                            Description
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="description"
                            value={editedVarset.description}
                            onChange={handleChange}
                            className="form-control-dark"
                        />
                    </Form.Group>
                    <Form.Group 
                        controlId="formVarsetMultiple" 
                        className="d-flex flex-column gap-0 align-items-start"
                    >
                        <Form.Check
                            type="checkbox"
                            label="Multiple Instances Allowed"
                            name="multiple"
                            checked={editedVarset.multiple}
                            onChange={handleChange}
                            className="ms-2 label-style-dark"
                        />
                    </Form.Group>

                    <h5 className="modal-form-heading mt-4 d-flex align-items-center">
                        Variables
                        <Button 
                            variant="link" 
                            onClick={handleAddVariable} 
                            className="ms-2 mt-0 mb-0"
                            style={{ color: 'white', fontSize: '1.5rem', lineHeight: '1' }}
                        >
                            <Plus style={{ color: 'white' }} />
                        </Button>
                    </h5>
                    {Array.isArray(editedVarset.variables) 
                        && editedVarset.variables.length > 0 ? (
                        editedVarset.variables.map((variable, index) => (
                            <div key={index} className="d-flex flex-column gap-3">
                                <div className="d-flex flex-row gap-3 w-100 align-items-center">
                                    <div className="d-flex flex-row gap-0 w-50 align-items-center">
                                    <Form.Group 
                                        controlId={`formVariableLabel${index}`} 
                                        className="d-flex flex-column gap-0 align-items-start w-100"
                                    >
                                        <Form.Label className="label-style-dark">
                                            Label
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={variable.variant}
                                            onChange={(e) => handleVariableChange(index, 'variant', e.target.value)}
                                            className="form-control-dark"
                                        />
                                    </Form.Group>
                                    {editedVarset.variables.length === 1 ? "" : 
                                    <Button 
                                        variant="link" 
                                        onClick={() => handleRemoveVariable(index)} 
                                        className="ms-0 pt-4"
                                        style={{ color: 'whitesmoke', fontSize: '1.5rem', lineHeight: '1' }}
                                    >
                                        <Trash />
                                    </Button>
                                    }
                                    </div>
                                    <Form.Group 
                                        controlId="formVarsetPlaceholder"
                                        className="d-flex flex-column gap-0 align-items-start w-50"
                                    >
                                        <Form.Label className="label-style-dark">
                                            Placeholder
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={variable.placeholder.startsWith('#') ? variable.placeholder.slice(1) : variable.placeholder}
                                            onChange={(e) => handleVariableChange(index, 'placeholder', e.target.value)}
                                            className="form-control-dark placeholder-with-hash"
                                        />
                                    </Form.Group>
                                </div>
                                <div className="d-flex flex-row gap-3 w-100">
                                    <Form.Group 
                                        controlId={`formVariableExample${index}`} 
                                        className="d-flex flex-column gap-0 align-items-start w-100"
                                    >
                                        <Form.Label className="label-style-dark">
                                            Example
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={variable.example}
                                            onChange={(e) => handleVariableChange(index, 'example', e.target.value)}
                                            className="form-control-dark"
                                        />
                                    </Form.Group>
                                </div>
                                <Form.Group 
                                    controlId={`formVariableSelect${index}`} 
                                    className="d-flex flex-column gap-0 align-items-start"
                                >
                                    <Form.Check
                                        type="checkbox"
                                        label="Multiple Choice?"
                                        checked={variable.select}
                                        onChange={(e) => handleVariableChange(index, 'select', e.target.checked)}
                                        className="ms-2 label-style-dark"
                                    />
                                </Form.Group>
                                {variable.select && (
                                    <Form.Group 
                                        controlId={`formVariableOptions${index}`} 
                                        className="d-flex flex-column gap-0 align-items-start"
                                    >
                                        <div className="d-flex align-items-center mb-2">
                                            <Form.Label className="label-style-dark m-0">
                                                Options
                                            </Form.Label>
                                            <Button 
                                                variant="link" 
                                                onClick={() => handleAddOption(index)} 
                                                className="button-link ms-3 p-0"
                                            >
                                                <Plus />
                                            </Button>
                                        </div>
                                        <div className="d-flex flex-wrap gap-3 w-100">
                                            {variable.options.map((option, optIndex) => (
                                                <div key={optIndex} className="d-flex align-items-center gap-0" style={{ width: '45%' }}>
                                                    <Form.Control
                                                        type="text"
                                                        value={option}
                                                        onChange={(e) => handleOptionChange(index, optIndex, e.target.value)}
                                                        className="form-control-dark"
                                                    />
                                                    {variable.options.length === 1 ? "" :
                                                        <Button 
                                                            variant="link" 
                                                            onClick={() => handleRemoveOption(index, optIndex)} 
                                                            className="button-link"
                                                        >
                                                            <Minus />
                                                        </Button>}
                                                </div>
                                            ))}
                                        </div>
                                    </Form.Group>
                                )}
                                <hr style={{ borderTop: '3px dashed #ccc' }} />
                            </div>
                        ))
                    ) : (
                        <p>No variables available to edit.</p>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="primary" 
                    onClick={() => {
                        resetForm();
                        handleClose();
                    }}
                    className="button-cancel-dark"
                    disabled={isSaving}
                >
                    Cancel
                </Button>
                <Button 
                    variant="secondary" 
                    onClick={handleSave}
                    className="button-save-dark"
                    disabled={isSaving}
                >
                    {isSaving ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditVariable;
